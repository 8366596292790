import React from 'react'
import {Helmet} from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import RichText from '../components/rich-text'
import { graphql } from 'gatsby'

const Title = ({title}) => <h1 className="section-headline">{title}</h1>

class PageTemplate extends React.Component {
	render() {
		const page = get(this.props, 'data.contentfulEverpost')
		const siteTitle = get(this.props, 'data.site.siteMetadata.title')
		const title = `${page.title} | ${siteTitle}`;
		return (
			<Layout location={this.props.location} title={title}>
				<div className="mainArticle">
					<div className="wrapper">
						<Title title={page.title}/>
						<RichText content={page.content}/>
					</div>
				</div>

			</Layout>
		)
	}
}

export default PageTemplate

export const pageQuery = graphql`
query EverpageById($id: String!) {
  contentfulEverpost(contentful_id: { eq: $id }) {
    id: contentful_id
    title
    createdAt: created(formatString: "MMMM Do, YYYY")
    content {
      raw
      references {
				... on ContentfulEverpost {
					# __typename is required to resolve the references
				  __typename
					# contentful_id is required to resolve the references
					contentful_id
				}
				... on ContentfulAsset {
					# __typename is required to resolve the references
				  __typename
					# contentful_id is required to resolve the references
					contentful_id
					file {
            url
          }
				}
			}
    }
    tags {
			id: contentful_id
			name
		}
  }
  site {
    siteMetadata {
      title
    }
  }
}
`
